import {styled} from "@mui/material/styles";
import Grid from "@mui/material/Grid2";

/**
 * Constantes spécifiques alvéole
 */
export const vousetessur = 'Home';
export const titleAlveole = 'Home';

/************************ DeV ******************************************************/
export const zUrlBackNotifications = process.env.REACT_APP_API_SERVICENOTIFICATION;
export const zUrlBack = process.env.REACT_APP_API_HOME;
export const domain = process.env.REACT_APP_DOMAIN;

/************************ LOCAL DeV ************************************************/
// export const zUrlBackNotifications = "https://localhost:8001";
// export const zUrlBack = "https://localhost:8000";
// export const domain = 'localhost';

export const CristalContainerAlveoleMulti = styled(Grid)(() => ({
    '@media (min-width: 0px) and (max-width: 566px)': {
       width: '380px'
    },
    '@media (min-width: 567px) and (max-width: 656px)': {
        width: '518px'
    },
    '@media (min-width: 657px)': {
        width: '600px'
    },
}));
export const alveolesParDefaut = [
    {
        id: 1,
        icon: 'icon-alv-meteo-sani-blanc',
        title: 'Météo sanitaire',
        label: 'actualite',
        link: process.env.REACT_APP_METEOSANITAIRE
    },
    {
        id: 2,
        icon: 'icon-alv-contact-blanc',
        title: 'Mes contacts',
        label: 'contacts',
        link: process.env.REACT_APP_CONTACTS
    },
    {
        id: 3,
        icon: 'icon-alv-form-blanc',
        title: 'Mes formations',
        label: 'espace_formation',
        link: process.env.REACT_APP_FORMATIONS
    },
    {
        id: 4,
        icon: 'icon-alv-rdv-blanc',
        title: 'Mes rendez-vous',
        label: 'rendez_vous',
        link: process.env.REACT_APP_RENDEZVOUS
    },
    {
        id: 5,
        icon: 'icon-alv-docs-veto-blanc',
        title: 'Mes documents véto',
        label: 'documents',
        link: process.env.REACT_APP_DOCUMENTSVETO
    },
    {
        id: 6,
        icon: 'icon-alv-docs-tech-blanc',
        title: 'Mes documents techniques',
        label: 'documents_techniques',
        link: ''
    },
    {
        id: 7,
        icon: 'icon-alv-factures-blanc',
        title: 'Mes factures',
        label: 'factures',
        link: process.env.REACT_APP_FACTURES
    },
    {
        id: 8,
        icon: 'icon-alv-espace-labo-blanc',
        title: 'Mon espace labo',
        label: 'espace_labo',
        link: process.env.REACT_APP_ESPACELABO
    },
    {
        id: 9,
        icon: 'icon-alv-dmd-pdts-blanc',
        title: 'Demande de produits',
        label: 'commande',
        link: process.env.REACT_APP_DEMANDEPRODUITS
    },
    {
        id: 10,
        icon: 'icon-alv-conso-blanc',
        title: 'Mes achats',
        label: 'consommations',
        link: process.env.REACT_APP_CONSOS
    },
];

/**
 * Fin constantes spécifiques alvéole
 */
/************************ Constantes ***********************************************/
export const zURLprofile = process.env.REACT_APP_PROFILE;
export const zURLhome = process.env.REACT_APP_HOME_CUSTOMER;
